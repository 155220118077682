import React from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationFormStep from "./RegistrationFormStep";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { useRegistrationStore } from "../../store/registrationStore";
import { NAME_NOT_VALID_ERROR_MSG } from "../../util/errorMessages";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import { BrandFooter } from "../shared/BrandFooter";
import PrimaryButton from "../shared/PrimaryButton";
import TextField from "../shared/TextField";
import { basicNameValidation } from "../util/account-fields-validation";

const ProfileCreation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    profileData,
    setFirstName,
    setLastName,
    incrementStep,
    decrementStep,
  } = useRegistrationStore();

  const handleSetFirstName = (value: string) => {
    setFirstName(value, false, "");
  };

  const handleSetLastName = (value: string) => {
    setLastName(value, false, "");
  };

  const goBack = () => {
    decrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep1_Password,
      queryClient,
      navigate,
      location
    );
  };

  const onContinueClick = () => {
    const isFirstNameValid = basicNameValidation(profileData.firstName.value);
    const isLastNameValid = basicNameValidation(profileData.lastName.value);

    if (!isFirstNameValid) {
      setFirstName(profileData.firstName.value, true, NAME_NOT_VALID_ERROR_MSG);
    }

    if (!isLastNameValid) {
      setLastName(profileData.lastName.value, true, NAME_NOT_VALID_ERROR_MSG);
    }

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      profileData.firstName.error ||
      profileData.lastName.error
    ) {
      return;
    }

    incrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep3_Account,
      queryClient,
      navigate,
      location
    );
  };

  return (
    <Box
      className="fade-in"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <RegistrationFormStep
        currentStep="2"
        numberOfSteps="3"
        decrementStep={goBack}
      />
      <Typography component="h1" variant="h3">
        What&apos;s your name?
      </Typography>
      <Box
        sx={{
          marginTop: "0.5rem",
          marginBottom: "2rem",
        }}
      >
        <TextField
          id="fName"
          label="First name"
          value={profileData.firstName.value}
          onChange={handleSetFirstName}
          customSx={{ marginY: "1.5rem" }}
          error={profileData.firstName.error}
          helperText={profileData.firstName.errorMessage}
          inputProps={{ maxLength: 150 }}
        />
        <TextField
          id="lName"
          label="Last name"
          value={profileData.lastName.value}
          onChange={handleSetLastName}
          customSx={{ marginBottom: "1.5rem" }}
          error={profileData.lastName.error}
          helperText={profileData.lastName.errorMessage}
          inputProps={{ maxLength: 150 }}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default ProfileCreation;
