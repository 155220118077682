import React, { ReactNode } from "react";

import PageCard from "../../components/shared/PageCard";

interface OwnProps {
  children: ReactNode;
}

const RegistrationLayout = ({ children }: OwnProps) => {
  return <PageCard>{children}</PageCard>;
};

export default RegistrationLayout;
