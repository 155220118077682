import React from "react";

function BeatportLogo() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Beatport"
    >
      <rect width="32" height="32" rx="2" fill="#01FF95" />
      <path
        d="M23 20.1883C23 23.4029 20.4465 26 17.2139 26C13.9813 26 11.46 23.4675 11.46 20.1883C11.46 18.6462 12.0416 17.2829 12.9632 16.2602L9.05244 20.1883L7 18.1267L11.4117 13.7439C12.0094 13.1437 12.3172 12.3635 12.3172 11.5035V6H15.2098V11.5035C15.2098 13.1922 14.6121 14.6201 13.4481 15.7893L13.3183 15.9197C14.3365 14.9778 15.7269 14.4099 17.2129 14.4099C20.4938 14.4099 22.999 17.0232 22.999 20.1893M20.3811 20.1893C20.3811 18.4683 18.9585 17.0727 17.2129 17.0727C15.4673 17.0727 14.0779 18.534 14.0779 20.1893C14.0779 21.8446 15.4673 23.3382 17.2129 23.3382C18.9585 23.3382 20.3811 21.8607 20.3811 20.1893Z"
        fill="#141414"
      />
    </svg>
  );
}

export default BeatportLogo;
