import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { login } from "../api/auth";
import { is401ClientError, is403ClientError } from "../api/httpClient";
import Box from "../bp-ui/components/Box";
import Typography from "../bp-ui/components/Typography";
import { PasswordField } from "../components/account/PasswordField";
import UsernameField from "../components/account/UsernameField";
import ForgotUsernameAndPassword from "../components/login/ForgotUsernameAndPassword";
import { SignUpText } from "../components/login/SignUpText";
import { BrandFooter } from "../components/shared/BrandFooter";
import ClosedAccountErrorAlert from "../components/shared/ClosedAccountErrorAlert";
import ErrorAlert from "../components/shared/ErrorAlert";
import PageCard from "../components/shared/PageCard";
import PrimaryButton from "../components/shared/PrimaryButton";
import {
  getAuthorizeEndpointUrl,
  navigateToLocation,
} from "../components/util/url";
import { LoginAlertState } from "../enums/alertStates/loginAlertState";
import { LoginFormData } from "../models/auth";
import { invalidateQueriesAndNavigateTo } from "../util/navigation";
import Pages from "../util/pages";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [alertState, setAlertState] = useState<LoginAlertState | null>(null);

  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ username, password }: LoginFormData) => {
      const response = await login({
        username,
        password,
      });
      const userData = response.data;
      return userData;
    },
    onSuccess: (userData) => {
      if (userData.email == "" || userData.email == null) {
        invalidateQueriesAndNavigateTo(
          Pages.updateEmailNotification,
          queryClient,
          navigate,
          location
        );
      } else {
        const authUrl = getAuthorizeEndpointUrl(location);

        if (authUrl !== null) {
          navigateToLocation(authUrl);
        } else {
          invalidateQueriesAndNavigateTo(
            Pages.settings,
            queryClient,
            navigate,
            location
          );
        }
      }
    },
    onError: (error: AxiosError) => {
      if (is403ClientError(error)) {
        setAlertState(LoginAlertState.Mismatch);
        return;
      }
      if (is401ClientError(error)) {
        setAlertState(LoginAlertState.Closed);
        return;
      }
      setAlertState(null);
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    mutate({ username: username, password: password });
  };

  return (
    <PageCard>
      <Typography component="h1" variant="h3">
        Log in
      </Typography>
      <SignUpText />
      <Box
        id="login-form"
        component="form"
        onSubmit={onSubmit}
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        {alertState === LoginAlertState.Mismatch && (
          <ErrorAlert
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
            message="Sorry, the username or password did not match our records. Please try
            again."
          />
        )}
        {alertState === LoginAlertState.Closed && (
          <ClosedAccountErrorAlert
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
          />
        )}
        <UsernameField value={username} onChange={setUsername} />
        <PasswordField
          id="password"
          label="Password"
          value={password}
          onChange={setPassword}
          customSx={{ marginY: "1.5rem" }}
        />
        <PrimaryButton type="submit" label="Log In" isLoading={isPending} />
      </Box>
      <ForgotUsernameAndPassword />
      <BrandFooter />
    </PageCard>
  );
};

export default LoginPage;
