import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { logout, reauthorize } from "../api/auth";
import Box from "../bp-ui/components/Box";
import Link from "../bp-ui/components/Link";
import Typography from "../bp-ui/components/Typography";
import { BrandFooter } from "../components/shared/BrandFooter";
import PageCard from "../components/shared/PageCard";
import PrimaryButton from "../components/shared/PrimaryButton";
import TextAction from "../components/shared/TextAction";
import Unauthorized from "../components/shared/Unauthorized";
import { currentUserQueryKey } from "../queries/useCurrentUser";
import { ThirdPartyAuthorization } from "../util/thirdPartyAuthorization";

const accessRequests = [
  "View your profile information",
  "View and manage your playlists and carts",
];

const ThirdPartyAuthorizationPage = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const params = new URLSearchParams(location.search);

  const logoutMutation = useMutation({
    mutationFn: async () => {
      await logout();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
      window.location.href = redirectUri;
    },
  });

  const { mutate } = useMutation({
    mutationFn: async (allow: boolean) => {
      return reauthorize(otherParams, key, allow);
    },
    onSuccess: (data) => {
      window.location.href = data;
    },
  });

  if (!ThirdPartyAuthorization.checkRequiredParamsExist(params)) {
    return <Unauthorized />;
  }

  const { clientApplicationName, username, redirectUri, key, otherParams } =
    ThirdPartyAuthorization.extractParams(params);

  const handleAccess = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    allow: boolean
  ) => {
    e.preventDefault();
    mutate(allow);
  };

  const handleAllowAccess = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleAccess(e, true);
  };

  const handleDenyAccess = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleAccess(e, false);
  };

  const handleNotYou = () => {
    logoutMutation.mutate();
  };

  return (
    <PageCard>
      <Typography
        component="h1"
        variant="h3"
        sx={{
          lineHeight: "2.5rem",
          marginBottom: "1rem",
        }}
      >
        Allow access
      </Typography>
      <Typography
        component="div"
        sx={{
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "1.5rem",
        }}
      >
        <strong>&quot;{clientApplicationName}&quot;</strong> is requesting to:
        {accessRequests.map((request, index) => (
          <Box
            key="index"
            id={`access-request-item-${index}`}
            sx={{ marginLeft: "0.75rem" }}
          >
            &#8226; {request}
          </Box>
        ))}
        <Typography
          sx={{
            marginTop: "2rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Allow access for: {username} (
          <Link onClick={handleNotYou}>
            <strong>Not you?</strong>
          </Link>
          )
        </Typography>
      </Typography>
      <Box
        id="allow-access-form"
        component="form"
        onSubmit={handleAllowAccess}
        sx={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <PrimaryButton type="submit" label="Allow Access" />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <TextAction text="Deny Access" onClick={handleDenyAccess} />
      </Box>
      <BrandFooter />
    </PageCard>
  );
};

export default ThirdPartyAuthorizationPage;
