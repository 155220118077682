import React, { useRef } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import SignInText from "./SignInText";
import { is417ClientError, isHttpClientError } from "../../api/httpClient";
import { validateCredentials } from "../../api/validation";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { useRegistrationStore } from "../../store/registrationStore";
import {
  EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG,
  EMAIL_NOT_VALID_ERROR_MSG,
  USERNAME_ALREADY_EXISTS_ERROR_MSG,
  USERNAME_NOT_VALID_ERROR_MSG,
} from "../../util/errorMessages";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import EmailField from "../account/EmailField";
import UsernameField from "../account/UsernameField";
import { BrandFooter } from "../shared/BrandFooter";
import PrimaryButton from "../shared/PrimaryButton";
import {
  basicEmailValidation,
  basicUsernameValidation,
} from "../util/account-fields-validation";

const SignUp = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { signUpData, setUsername, setEmail, incrementStep } =
    useRegistrationStore();

  const usernameValidationInProgressRef = useRef(false);
  const emailValidationInProgressRef = useRef(false);

  const {
    mutate: mutateValidateUsername,
    isPending: isValidateUsernameLoading,
  } = useMutation({
    mutationFn: async (value: string) => {
      usernameValidationInProgressRef.current = true;

      const isValid = basicUsernameValidation(value);

      if (!isValid) {
        throw new Error("Invalid username.");
      }

      await validateCredentials("username", value);
    },
    onError: (error) => {
      let errorMessage = USERNAME_NOT_VALID_ERROR_MSG;

      if (isHttpClientError(error)) {
        errorMessage = USERNAME_ALREADY_EXISTS_ERROR_MSG;
      }

      setUsername(signUpData.username.value, true, errorMessage);
    },
    onSettled: () => {
      usernameValidationInProgressRef.current = false;
      handleIncrementStep();
    },
  });

  const { mutate: mutateValidateEmail, isPending: isValidateEmailLoading } =
    useMutation({
      mutationFn: async (value: string) => {
        emailValidationInProgressRef.current = true;
        const isValid = basicEmailValidation(value);

        if (!isValid) {
          throw new Error("Invalid email.");
        }

        await validateCredentials("email", value);
      },
      onError: (error) => {
        let errorMessage = EMAIL_NOT_VALID_ERROR_MSG;

        if (isHttpClientError(error) && is417ClientError(error as AxiosError)) {
          errorMessage = EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG;
        }
        setEmail(signUpData.email.value, true, errorMessage);
      },
      onSettled: () => {
        emailValidationInProgressRef.current = false;
        handleIncrementStep();
      },
    });

  const handleIncrementStep = () => {
    if (
      usernameValidationInProgressRef.current === true ||
      emailValidationInProgressRef.current === true
    ) {
      return;
    }
    if (signUpData.email.error || signUpData.username.error) {
      return;
    }
    incrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep1_Password,
      queryClient,
      navigate,
      location
    );
  };

  const handleSetUsername = (value: string) => {
    setUsername(value, false, "");
  };

  const handleSetEmail = (value: string) => {
    setEmail(value, false, "");
  };

  const onContinueClick = async () => {
    mutateValidateUsername(signUpData.username.value);
    mutateValidateEmail(signUpData.email.value);
  };

  const isLoading = () => {
    return isValidateEmailLoading || isValidateUsernameLoading;
  };

  return (
    <Box
      className="fade-in"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography component="h1" variant="h3">
        Sign up
      </Typography>

      <SignInText />
      <Box
        sx={{
          marginY: "2rem",
        }}
      >
        <UsernameField
          value={signUpData.username.value}
          onChange={handleSetUsername}
          error={signUpData.username.error}
          helperText={signUpData.username.errorMessage}
        />
        <EmailField
          value={signUpData.email.value}
          error={signUpData.email.error}
          helperText={signUpData.email.errorMessage}
          onChange={handleSetEmail}
          sx={{ marginY: "1.5rem" }}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
          isLoading={isLoading()}
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default SignUp;
