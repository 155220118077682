import React from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationFormStep from "./RegistrationFormStep";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { useRegistrationStore } from "../../store/registrationStore";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import { PasswordField } from "../account/PasswordField";
import { BrandFooter } from "../shared/BrandFooter";
import PrimaryButton from "../shared/PrimaryButton";
import { basicPasswordValidation } from "../util/account-fields-validation";

const passwordRequirementsMsg = "Must be at least 8 characters long";

const PasswordCreation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { passwordData, setPassword, incrementStep, decrementStep } =
    useRegistrationStore();

  const handleSetPassword = (value: string) => {
    if (value.length === 0) {
      setPassword(value, true, passwordRequirementsMsg);
      return;
    }

    setPassword(value, false, passwordData.password.errorMessage);
  };

  const goBack = () => {
    decrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep0,
      queryClient,
      navigate,
      location
    );
  };

  const onContinueClick = () => {
    const isValid = basicPasswordValidation(passwordData.password.value);

    if (!isValid) {
      setPassword(passwordData.password.value, true, passwordRequirementsMsg);
      return;
    }

    incrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep2_Profile,
      queryClient,
      navigate,
      location
    );
  };

  return (
    <Box
      className="fade-in"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <RegistrationFormStep
        currentStep="1"
        numberOfSteps="3"
        decrementStep={goBack}
      />
      <Typography component="h1" variant="h3">
        Create a password
      </Typography>
      <Box
        sx={{
          marginTop: "0.5rem",
          marginBottom: "2rem",
        }}
      >
        <PasswordField
          id="password"
          label="Password"
          customSx={{ marginY: "1.5rem" }}
          value={passwordData.password.value}
          onChange={handleSetPassword}
          error={passwordData.password.error}
          helperText={passwordRequirementsMsg}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default PasswordCreation;
