import React, { useContext, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { sendForgotPassword } from "../../api/auth";
import {
  is401ClientError,
  is404ClientError,
  is429ClientError,
} from "../../api/httpClient";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import EmailField from "../../components/account/EmailField";
import PageCard from "../../components/shared/PageCard";
import PrimaryButton from "../../components/shared/PrimaryButton";
import SecondaryButtonLink from "../../components/shared/SecondaryButton";
import { ForgotPasswordContext } from "../../context/ForgotPasswordContext";
import { PasswordResetEmailAlertState } from "../../enums/alertStates/passwordResetEmailAlertState";
import Pages from "../../util/pages";
import { BrandFooter } from "../shared/BrandFooter";
import ClosedAccountErrorAlert from "../shared/ClosedAccountErrorAlert";
import ErrorAlert from "../shared/ErrorAlert";
import LimitErrorAlert from "../shared/LimitErrorAlert";
import { getSharedQueryParamsString } from "../util/url";

export const SendPasswordResetEmail = () => {
  const { email, setEmail, setEmailSent } = useContext(ForgotPasswordContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [alertState, setAlertState] =
    useState<PasswordResetEmailAlertState | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: async (email: string) => {
      await sendForgotPassword({
        email: email,
      });
    },
    onSuccess() {
      setEmailSent(true);
      setAlertState(null);
    },
    onError(error: AxiosError) {
      if (is404ClientError(error)) {
        // Simulate that email was sent successfully even if user doesn't exist
        setEmailSent(true);
        setAlertState(null);
        return;
      }
      if (is401ClientError(error)) {
        setEmailSent(false);
        setAlertState(PasswordResetEmailAlertState.Closed);
        return;
      }
      if (is429ClientError(error)) {
        setAlertState(PasswordResetEmailAlertState.Limit);
        return;
      }
      setEmailSent(false);
      setAlertState(PasswordResetEmailAlertState.Generic);
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    mutate(email);
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    setAlertState(null);
  };

  const onCancel = () => {
    navigate(Pages.home + getSharedQueryParamsString(location, true));
  };

  return (
    <PageCard>
      <Box
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            lineHeight: "2.5rem",
            marginBottom: "1rem",
          }}
        >
          Forgot username or password?
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "1.5rem",
          }}
        >
          Enter the email address linked to your account.
        </Typography>
      </Box>
      <Box
        id="forgot-password-or-username-form"
        component="form"
        onSubmit={onSubmit}
        sx={{
          marginBottom: "2rem",
        }}
      >
        {alertState === PasswordResetEmailAlertState.Generic && (
          <ErrorAlert
            message="Failed to send password reset email."
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
          />
        )}
        {alertState === PasswordResetEmailAlertState.Closed && (
          <ClosedAccountErrorAlert
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
          />
        )}
        {alertState === PasswordResetEmailAlertState.Limit && (
          <LimitErrorAlert
            customSx={{ marginY: "1.5rem", lineHeight: "1rem" }}
          />
        )}
        <EmailField value={email} onChange={onEmailChange} />
        <PrimaryButton
          type="submit"
          label="Send Email"
          isLoading={isPending}
          sx={{ marginY: "1.5rem" }}
          isDisabled={!email}
          formNoValidate={true}
        />
        <SecondaryButtonLink onClick={onCancel} label="Cancel" />
      </Box>
      <BrandFooter />
    </PageCard>
  );
};
