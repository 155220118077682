import React, { ReactNode, useLayoutEffect } from "react";

import { BrandFooter } from "./BrandFooter";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Container from "../../bp-ui/components/Container";
import { isMobileScreen, mobileScreenSx } from "../util/screen";

interface OwnProps {
  children: ReactNode;
}

const FullPageLayout = ({ children }: OwnProps) => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = isMobileScreen()
      ? BPColors.Black
      : BPColors.White;
  });

  return (
    <Box
      sx={{
        minWidth: "20rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ...mobileScreenSx({
          backgroundColor: "black",
        }),
      }}
    >
      <Container
        component="main"
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1.5rem",
          flexGrow: 1,
          ...mobileScreenSx({
            backgroundColor: "white",
            marginTop: "2rem",
            borderRadius: "1.5rem 1.5rem 0 0",
            paddingX: "1.5rem",
            paddingY: "1.5rem",
            gap: "1.5rem",
          }),
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            marginX: "auto",
            justifyContent: "center",
            alignItems: "center",
            ...mobileScreenSx({
              width: "100%",
            }),
          }}
        >
          {children}
        </Box>

        <BrandFooter
          customSx={{
            marginTop: "1.5rem",
            ...mobileScreenSx({
              marginY: "0",
            }),
          }}
        />
      </Container>
    </Box>
  );
};

export default FullPageLayout;
