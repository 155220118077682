import React from "react";

function BeatsourceLogo() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Beatsource"
    >
      <rect width="32" height="32" rx="2" fill="#027EDD" />
      <path
        d="M17.0133 11.687C15.1323 11.687 13.4237 12.4003 12.166 13.5561V7H8V24.7225H12.166V23.1309C13.4232 24.2871 15.1319 25 17.0133 25C20.8656 25 24 22.0136 24 18.3433C24 14.6729 20.8656 11.6865 17.0133 11.6865V11.687Z"
        fill="white"
      />
    </svg>
  );
}

export default BeatsourceLogo;
