import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import AccountSettingsField from "./AccountSettingsField";
import { logout } from "../../api/auth";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import WarningIcon from "../../bp-ui/icons/WarningIcon";
import { User } from "../../models/user";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import useAuthCurrentUserQuery from "../../util/useAuthCurrentUserQuery";
import Unauthorized from "../shared/Unauthorized";
import { mobileScreenSx } from "../util/screen";

const isEmailEmpty = (email: string | null) => {
  return email == null || email == "";
};

const getEmailFieldText = (email: string | null) => {
  if (isEmailEmpty(email)) return "Update email address";
  return email;
};

const getEmailLabelText = (currentUser: User): string => {
  if (isEmailEmpty(currentUser.email)) return "";
  if (currentUser.emailConfirmed) return "Email settings";
  return "Email settings (unverified)";
};

const AccountInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const currentUser = useAuthCurrentUserQuery(navigate, location, queryClient);

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  const logoutMutation = useMutation({
    mutationFn: async () => {
      await logout();
    },
    onSuccess: () => {
      navigateTo(Pages.home);
    },
  });

  const onLogoutClick = () => {
    logoutMutation.mutate();
  };

  if (currentUser === null) {
    return <Unauthorized />;
  }

  return (
    <Box
      sx={{
        width: "24rem",
        color: BPColors.PrimaryMain,
        ...mobileScreenSx({
          width: "100%",
        }),
      }}
    >
      <Typography component="h1" variant="h3">
        Account settings
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <AccountSettingsField
          customSx={{
            "&:hover": {},
          }}
          label="Username"
          text={currentUser.username}
        />
        <AccountSettingsField
          label={getEmailLabelText(currentUser)}
          text={getEmailFieldText(currentUser.email)}
          showIcon={
            !currentUser.emailConfirmed || isEmailEmpty(currentUser.email)
          }
          icon={<WarningIcon />}
          onEditClicked={() => navigateTo(Pages.emailEdit)}
          customSx={{
            marginY: "1.5rem",
            justifyContent: "flex-start",
            "#account-field-icon": {
              marginRight: "0.5rem",
              marginY: "auto",
            },
            "#account-field-edit-icon": {
              marginLeft: "auto",
            },
          }}
        />
        <AccountSettingsField
          label="Change password"
          text="••••••••••"
          onEditClicked={() => navigateTo(Pages.passwordEdit)}
        />
        <AccountSettingsField
          label="Edit name"
          text={`${currentUser.firstName} ${currentUser.lastName}`}
          onEditClicked={() => navigateTo(Pages.profileEdit)}
          customSx={{
            marginY: "1.5rem",
          }}
        />
      </Box>
      <Box
        sx={{
          paddingX: "1rem",
          paddingY: "0.75rem",
          border: "0.063rem solid #E8EBF1",
          borderRadius: "0.25rem",
          display: "flex",
          justifyContent: "space-between",
          minHeight: "3.75rem",
          cursor: "pointer",
          "&:hover": {
            bgcolor: BPColors.SecondaryMain,
          },
        }}
        onClick={onLogoutClick}
      >
        <Box
          id="logout-button"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "80%",
          }}
        >
          <Box id="logout-text" sx={{ marginY: "auto" }}>
            <Typography
              sx={{
                color: "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="body1"
            >
              Log out everywhere
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "0.5rem" }}>
        <Typography
          sx={{ color: "#696B70", fontSize: "0.75rem", fontStyle: "normal" }}
        >
          This will log you out of all Beatport Group products within 10 minutes
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountInfo;
