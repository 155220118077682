import React from "react";

import { Layout } from "./Layout";
import Box from "../../bp-ui/components/Box";
import Container from "../../bp-ui/components/Container";
import { mobileScreenSx } from "../util/screen";

interface OwnProps {
  children: React.ReactNode;
}

const PageCard = ({ children }: OwnProps) => {
  return (
    <Layout>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          maxWidth: "30rem!important",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          ...mobileScreenSx({
            marginTop: "2rem",
            marginBottom: "0",
          }),
          padding: "0!important",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            background: "white",
            borderRadius: "1.5rem",
            padding: "2.5rem",

            ...mobileScreenSx({
              padding: "1.5rem",
              height: "100%",
              borderRadius: "1.5rem 1.5rem 0 0",
              display: "flex",
              flexDirection: "column",
            }),
          }}
        >
          {children}
        </Box>
      </Container>
    </Layout>
  );
};

export default PageCard;
