import React from "react";

import BeatportLogo from "../../bp-ui/branding/BeatportLogo";
import BeatsourceLogo from "../../bp-ui/branding/BeatsourceLogo";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { CustomSx } from "../../models/custom-sx";
import { mobileScreenSx } from "../util/screen";

interface OwnProps {
  customSx?: CustomSx;
}

export const BrandFooter = ({ customSx }: OwnProps) => {
  return (
    <Box
      sx={{
        marginTop: "2rem",
        borderTop: `0.063rem solid ${BPColors.SecondaryMain}`,
        paddingTop: "1rem",
        display: "flex",
        justifyContent: "space-between",
        ...customSx,
        ...mobileScreenSx({ marginTop: "auto" }),
      }}
    >
      <Typography
        variant="body2"
        color="primary.light"
        sx={{
          marginTop: "0.5rem",
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
          fontStyle: "normal",
          fontWeight: "400",
          "& span": {
            textDecorationLine: "underline",
          },
        }}
        gutterBottom
      >
        One account across The Beatport Group.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <BeatportLogo />
        <BeatsourceLogo />
      </Box>
    </Box>
  );
};
